import React from "react";
import Icon from "../../assets/images/Icon.svg";
import CloseIcon from "../../assets/images/Frame 98.svg";
import eyes from "../../assets/images/eyes.svg";

export default function CreateSupplierdlg() {
  return (
    <div>
      <button
        onClick={() => document.getElementById("my_modal_1").showModal()}
        className="h-[41px] w-full sm:w-[150px] mt-3 sm:mt-0 rounded-[10px] bg-primary-dark"
      >
        <ul className="inline-flex items-center space-x-2">
          <li>
            <img src={Icon} className="w-4 h-4" alt="" />
          </li>
          <li className="f-f-sm-n text-base text-white-light">Add Supplier</li>
        </ul>
      </button>

      <dialog id="my_modal_1" className="modal text-start custom-scrollbar">
        <div className="modal-box sm:min-w-[631px] createdlg p-4 sm:p-[30px] ">
          <h3 className="  text-grey f-f-sm-r text-2xl sm:text-3xl ">
            Add Supplier
          </h3>

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Company Name
          </h6>
          <input
            type="text"
            className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Company name"
          />

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Country
          </h6>
          <input
            type="text"
            className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Country Name"
          />

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Province
          </h6>
          <input
            type="text"
            className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Province Name"
          />

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Working Area
          </h6>
          <input
            type="text"
            className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Enter Working Area"
          />

          <select
            id="countries"
            className="custom-select mt-[30px] w-full h-[40px] sm:h-[44px] rounded border border-blue-dark  bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Enter Working Area"
          >
            <option className="text-primary " selected>
              Select
            </option>
            <option className="text-primary " value="US">
              United States
            </option>
            <option className="text-primary " value="CA">
              Canada
            </option>
            <option className="text-primary " value="FR">
              France
            </option>
            <option className="text-primary " value="DE">
              Germany
            </option>
          </select>

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Phone Number
          </h6>
          <input
            type="text"
            className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Enter Phone Number"
          />

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Email
          </h6>
          <input
            type="text"
            className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
            placeholder="Email"
          />

          <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
            Password
          </h6>
          <div className=" relative ">
            <input
              type="text"
              className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
              placeholder="Enter Password"
            />
            <img
              src={eyes}
              className=" w-[20px] h-[20px] absolute top-[20px] right-[11px] "
            />
          </div>

          <button className="w-full h-[35px] sm:h-[41px] mt-[45px] rounded-[10px] bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light">
            Create Account
          </button>

          <form method="dialog">
            <button className=" absolute top-[30px] right-[26px] ">
              {" "}
              <img src={CloseIcon} className=" w-[22px] h-[22px]  " />{" "}
            </button>
          </form>
        </div>
      </dialog>
    </div>
  );
}
