import React from "react";
import { useSalesPerTankSize } from "../../api";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Line, Doughnut } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TanksGraph() {
  const { salesPerTankSize } = useSalesPerTankSize();

  const sortedSalesPerTankSize = salesPerTankSize.sort(
    (a, b) => a.cylinderSize - b.cylinderSize
  );
  const sizes = sortedSalesPerTankSize.map((tank) => `${tank.cylinderSize} kg`);
  const counts = sortedSalesPerTankSize.map((tank) => tank.count);

  const data = {
    labels: sizes,
    datasets: [
      {
        data: counts,
        backgroundColor: "#7858F4",
        fill: true,
        borderColor: "#7858F4",
        borderWidth: 3,
        tension: 0.4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 0,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#FFFFFF",
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          color: "#FFFFFF",
          callback: function (value) {
            return Number.isInteger(value) ? value : "";
          },
          stepSize: 1,
        },
      },
    },
  };

  return (
    <div className=" numbertankscrd py-[33px] px-[30px] h-full ">
      <h3 className=" f-f-sm-p text-grey text-3xl ">Number of Sales</h3>

      <div className="h-[80%] mt-4">
        <Line data={data} options={options} />
      </div>
      <div className="text-center mt-4">
        <ul className=" inline-flex space-x-2 items-center text-grey f-f-r-p text-sm ">
          <li>
            <div className=" bg-primary-light rounded-[2px] w-[13px] h-[13px] "></div>
          </li>
          <li>Number of Sales Per Tank Size</li>
        </ul>
      </div>
    </div>
  );
}
