import React from 'react'
import Siderbar from './Sidebar'

export default function Mobiledrawer() {
  return (
    <div>
      <div className="drawer">
        <input id="my-drawer" type="checkbox" className="drawer-toggle" />
        <div className="drawer-content">
          {/* Page content here */}
          <label htmlFor="my-drawer" className=" drawer-button  cursor-pointer  "><svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#EEF0FC" viewBox="0 0 17 14">
            <path stroke="#EEF0FC" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
          </svg></label>
        </div>
        <div className="drawer-side z-10">
          <label htmlFor="my-drawer" aria-label="close sidebar" className="drawer-overlay"></label>

          <Siderbar />

        </div>
      </div>
    </div>
  )
}
