import React, { useState } from "react";
import CloseIcon from "../../assets/images/Frame 98.svg";
import { useUpdateOrderStatus } from "../../api/index";

export default function EditOrderStatusDialog({ orderId }) {
  const [status, setStatus] = useState("");

  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");

  const showToast = (msg, type) => {
    setToastType(type);
    setToastMsg(msg);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 4000);
  };

  const { updateOrderStatus, loading } = useUpdateOrderStatus();

  const handleUpdate = async () => {
    const { success, error } = await updateOrderStatus(orderId, status);

    if (success) {
      showToast("Status Updated", "alert-success");
    } else if (error) {
      showToast("Error updating order status", "alert-error");
    }
  };

  return (
    <dialog
      id="edit_order_status_modal"
      className="modal text-start custom-scrollbar"
    >
      {toast === true ? (
        <div className="toast toast-center toast-top">
          <div className={`alert ${toastType} text-white`}>{toastMsg}</div>
        </div>
      ) : null}

      <div className="modal-box sm:min-w-[631px] createdlg p-4 sm:p-[30px] ">
        <h3 className="  text-grey f-f-sm-r text-2xl sm:text-3xl ">
          Edit Order Status
        </h3>

        <p className="py-6">
          Update the current status of this order to reflect its latest
          progress.
        </p>

        <select
          value={status}
          onChange={(e) => {
            setStatus(e.target.value);
          }}
          className="w-full select select-bordered bg-transparent"
        >
          <option value="" disabled>
            Choose
          </option>
          <option value="waiting">Waiting</option>
          <option value="processing">Processing</option>
          <option value="completed">Completed</option>
        </select>

        <div className="flex justify-end mt-6">
          <button
            disabled={loading}
            onClick={handleUpdate}
            className="btn bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light hover:bg-primary-dark"
          >
            Save
          </button>
        </div>

        <form method="dialog">
          <button className=" absolute top-[30px] right-[26px] ">
            <img src={CloseIcon} className=" w-[22px] h-[22px]" />
          </button>
        </form>
      </div>
    </dialog>
  );
}
