import "./App.css";
import Manageuser from "./components/Manage-users/manageusers";
import Managedriver from "./components/Manage-Drivers/managedrivers";
import Managesupplier from "./components/Manage-supplier";
import Suppersupplier from "./components/Supper-supplier";
import Roles from "./components/Roles";
import Statistics from "./components/Statistics/Index";
import Cylinder from "./components/Cylinder/cylinders";
import Gas from "./components/Gas-orders/gasorders";
import Supplierdetail from "./components/Manage-supplier-detail";
import Supperdetail from "./components/Supper-supplier-detail";
import Signin from "./components/Signin/signin";
import Userdetail from "./components/Manage-users-detail";
import Withdraw from "./components/Withdraw";
import Setup from "./components/Set-up-withdrawl";
import Managesupplier2 from "./components/Manage-supplier2";
import Earning from "./components/Earning/earnings";
import Setting from "./components/Setting";
import { Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import RequestAccount from "./components/Request-account/requestacc";
import { useAuth } from "./api/auth";
import ManageUsers from "./components/Manage-users/manageusers";
import ManageDrivers from "./components/Manage-Drivers/managedrivers";
import Cylinders from "./components/Cylinder/cylinders";
import GasOrders from "./components/Gas-orders/gasorders";
import ViewDriver from "./components/View-Driver";

function App() {
  const { auth, setAuthState } = useAuth();

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const accountType = localStorage.getItem("accountType");
    if (accessToken && accountType) {
      setAuthState(accessToken, accountType);
    }
  }, [setAuthState]);

  if (auth.accessToken && auth.accountType === "supplier") {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Statistics />} />
          <Route path="/manageusers" element={<ManageUsers />} />
          <Route path="/manageusers/:id" element={<Userdetail />} />
          <Route path="/managedrivers" element={<ManageDrivers />} />
          <Route path="/managedrivers/:id" element={<ViewDriver />} />
          <Route path="/cylinders" element={<Cylinders />} />
          <Route path="/gasorders" element={<GasOrders />} />
          <Route path="/earnings" element={<Earning />} />
          <Route path="/withdraw" element={<Withdraw />} />
        </Routes>
      </div>
    );
  } else if (auth.accessToken && auth.accountType === "distributor") {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Managesupplier />} />
          <Route path="/supplierdetail" element={<Supplierdetail />} />
        </Routes>
      </div>
    );
  } else if (auth.accessToken && auth.accountType === "admin") {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Statistics />} />
          <Route path="/roles" element={<Roles />} />
          <Route path="/cylinder" element={<Cylinder />} />
          <Route path="/gas" element={<Gas />} />
          <Route path="/managedriver" element={<Managedriver />} />
          <Route path="/managesupplier" element={<Managesupplier />} />
          <Route path="/manageuser" element={<Manageuser />} />
          <Route path="/suppersupplier" element={<Suppersupplier />} />
          <Route path="/supplierdetail" element={<Supplierdetail />} />
          <Route path="/supperdetail" element={<Supperdetail />} />
          <Route path="/userdetail" element={<Userdetail />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/managesupplier2" element={<Managesupplier2 />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/earning" element={<Earning />} />
        </Routes>
      </div>
    );
  }

  return (
    <div className="h-screen overflow-y-auto">
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/requestaccount" element={<RequestAccount />} />
      </Routes>
    </div>
  );
}

export default App;
