import React from 'react'
import Kala from '../../assets/images/Kala.svg'
import Ladie from '../../assets/images/Ladiewhite.svg'
import Sufi from '../../assets/images/Sufi.svg'
import Karel from '../../assets/images/Karel.svg'
import Icon from '../../assets/images/Icon.svg'
import arrowack from '../../assets/images/arrowack.svg'
import arrowforwed from '../../assets/images/arrowforwed.svg'
import { Link } from 'react-router-dom'
import CreateSupplierdlg from './CreateSupplierdlg'

export default function table() {
    return (
        <div className='p-[20px] sm:p-[34px]'>
            <div className="grid grid-cols-12 mb-10 mt-[14px] ">
                <div className="col-span-12 sm:col-span-6">
                    <h1 className='f-f-m-p text-3xl text-grey text-center sm:text-start'>Manage Supplier</h1>
                </div>

                <div className=" col-span-12 sm:col-span-6">
                    <div className="sm:text-end">
                        <CreateSupplierdlg/>
                    </div>
                </div>
            </div>

            <div className=' pb-[200px] ' >
                <div className=' border  border-blue-dark  rounded mt-5   ' >
                    <div className="table-bg relative overflow-x-auto rounded    rounded-t">
                        <Link to="/supplierdetail" >
                            <table className="w-full  ">
                                <thead className=" border-b border-blue-dark ">
                                    <tr className=' text-left'>
                                        <td className="pl-5 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[246px] ">
                                            Name
                                        </td>

                                        <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[184px] ">
                                            Phone
                                        </td>

                                        <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[184px] ">
                                            Email
                                        </td>

                                        <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[234px] ">
                                            Account Creation
                                        </td>

                                        <td className="px-4 2xl:px-10 py-[10px] f-f-r-r text-tiny text-grey min-w-[200px] lg:min-w-fit 3xl:min-w-[184px] ">
                                            Last Login
                                        </td>

                                    </tr>
                                </thead>
                                <tbody>

                                    <tr className=" border-b border-blue-dark items-center  ">

                                        <td className="pl-5 py-4">
                                            <ul className='inline-flex items-center space-x-[23px]'>
                                                <li><img src={Kala} className='w-10 h-10' alt="" /></li>
                                                <li className='f-f-m-r text-base text-grey'>Joseph Chizororo</li>
                                            </ul>
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>+263795822953
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>cjosephs@ymail.com
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>Friday 2024-01-28</h1>
                                            <h2>07:43</h2>
                                        </td>
                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>2024-01-28</h1>
                                            <h2>07:43:02.664212</h2>
                                        </td>

                                    </tr>

                                    <tr className=" border-b border-blue-dark items-center  ">

                                        <td className="pl-5 py-4">
                                            <ul className='inline-flex items-center space-x-[23px]'>
                                                <li><img src={Ladie} className='w-10 h-10' alt="" /></li>
                                                <li className='f-f-m-r text-base text-grey'>Frank Foka</li>
                                            </ul>
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>+237695546648
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>frankwabo5@gmail.com
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>Wednesday 2024-01-17</h1>
                                            <h2>09:18</h2>
                                        </td>
                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>2024-01-17
                                        </h1>
                                            <h2>09:18:56.619699</h2>
                                        </td>

                                    </tr>



                                    <tr className=" border-b border-blue-dark items-center  ">

                                        <td className="pl-5 py-4">
                                            <ul className='inline-flex items-center space-x-[23px]'>
                                                <li><img src={Sufi} className='w-10 h-10' alt="" /></li>
                                                <li className='f-f-m-r text-base text-grey'>sufi</li>
                                            </ul>
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>+92315965522
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>sufi.baba111@gmail.com
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>Wednesday 2024-01-17</h1>
                                            <h2>09:18</h2>
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-green'>
                                            Online
                                        </td>

                                    </tr>


                                    <tr className=" border-b border-blue-dark items-center  ">

                                        <td className="pl-5 py-4">
                                            <ul className='inline-flex items-center space-x-[23px]'>
                                                <li><img src={Kala} className='w-10 h-10' alt="" /></li>
                                                <li className='f-f-m-r text-base text-grey'>Siphiwe Mabusela</li>
                                            </ul>
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>+27608548855
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>hello@stratida.com
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>Wednesday 2024-01-17</h1>
                                            <h2>09:18</h2>
                                        </td>
                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>2024-03-06
                                        </h1>
                                            <h2>18:24:04.308652</h2>
                                        </td>

                                    </tr>


                                    <tr className=" border-b border-blue-dark items-center  ">

                                        <td className="pl-5 py-4">
                                            <ul className='inline-flex items-center space-x-[23px]'>
                                                <li><img src={Karel} className='w-10 h-10' alt="" /></li>
                                                <li className='f-f-m-r text-base text-grey'>Karel Venter</li>
                                            </ul>
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>+27833436469
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'>karelv@gmail.com
                                        </td>

                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-grey-light'><h1>Wednesday 2024-01-17</h1>
                                            <h2>09:18</h2>
                                        </td>
                                        <td className='px-4 2xl:px-10 py-4 f-f-r-r text-base text-green'>
                                            Online
                                        </td>

                                    </tr>




                                </tbody>
                            </table>
                        </Link>
                    </div>

                    <nav className="  w-full py-4  sm:px-[34px] " aria-label="Table navigation">
                        <div className="grid grid-cols-12 items-center ">
                            <div className=" col-span-12 sm:col-span-6 ">
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse  h-[36px] ">
                                    <li>
                                        <a href="#" className="flex items-center justify-center px-3 h-[36px]  ms-0 leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-l-[4px] ">
                                            <ul className=' inline-flex ' >
                                                <li>
                                                    <img src={arrowack} className=' w-[14px] h-[16px] ' />
                                                </li>
                                                <li className=' ml-[3px] ' >
                                                    Previous
                                                </li>
                                            </ul>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="flex items-center justify-center px-3 h-[36px]  leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark ">1</a>
                                    </li>
                                    <li>
                                        <a href="#" className="flex items-center justify-center px-3 h-[36px]  leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark ">2</a>
                                    </li>
                                    <li>
                                        <a href="#" aria-current="page" className="flex items-center justify-center px-3 h-[36px]  text-blue-600 border border-blue-dark  "><svg width="13" height="3" viewBox="0 0 13 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M6.5 2.9375C6.1276 2.9375 5.80599 2.80632 5.53516 2.54395C5.27279 2.28158 5.1416 1.95996 5.1416 1.5791C5.1416 1.20671 5.27279 0.889323 5.53516 0.626953C5.80599 0.364583 6.1276 0.233398 6.5 0.233398C6.8724 0.233398 7.18978 0.364583 7.45215 0.626953C7.72298 0.889323 7.8584 1.20671 7.8584 1.5791C7.8584 1.9515 7.72298 2.27311 7.45215 2.54395C7.18978 2.80632 6.8724 2.9375 6.5 2.9375ZM10.8291 2.9375C10.4567 2.9375 10.1393 2.80632 9.87695 2.54395C9.61458 2.28158 9.4834 1.95996 9.4834 1.5791C9.4834 1.20671 9.61458 0.889323 9.87695 0.626953C10.1393 0.364583 10.4567 0.233398 10.8291 0.233398C11.21 0.233398 11.5316 0.364583 11.7939 0.626953C12.0563 0.889323 12.1875 1.20671 12.1875 1.5791C12.1875 1.9515 12.0521 2.27311 11.7812 2.54395C11.5189 2.80632 11.2015 2.9375 10.8291 2.9375ZM2.1709 2.9375C1.79004 2.9375 1.46842 2.80632 1.20605 2.54395C0.943685 2.28158 0.8125 1.95996 0.8125 1.5791C0.8125 1.20671 0.943685 0.889323 1.20605 0.626953C1.46842 0.364583 1.79004 0.233398 2.1709 0.233398C2.54329 0.233398 2.86068 0.364583 3.12305 0.626953C3.38542 0.889323 3.5166 1.20671 3.5166 1.5791C3.5166 1.9515 3.38542 2.27311 3.12305 2.54395C2.86068 2.80632 2.54329 2.9375 2.1709 2.9375Z" fill="#EEF0FC" />
                                        </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="flex items-center justify-center px-3 h-[36px]  leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark ">4</a>
                                    </li>
                                    <li>
                                        <a href="#" className="flex items-center justify-center px-3 h-[36px]  leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark ">5</a>
                                    </li>
                                    <li>
                                        <a href="#" className="flex items-center justify-center px-3 h-[36px]  leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-r-[4px] ">
                                            <ul className=' inline-flex ' >

                                                <li  >
                                                    Next
                                                </li>
                                                <li className=' ml-[3px] ' >
                                                    <img src={arrowforwed} className=' w-[14px] h-[16px] ' />
                                                </li>
                                            </ul>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className=" col-span-12 sm:col-span-6 text-center sm:text-end mt-4 sm:mt-0 ">
                                <ul className=' inline-flex space-x-4 items-center ' >
                                    <li>
                                        <h2 className='text-grey f-f-r-r text-tiny  ' >
                                            PAGE
                                        </h2>
                                    </li>
                                    <li>
                                        <select className=' rounded-sm outline-none w-[59px] h-[36px] bg-transparent border border-grey-dark text-grey f-f-r-r text-tiny px-3 ' >
                                            <option className=' text-primary ' value="0">1</option>
                                            <option className=' text-primary ' value="1">2</option>
                                            <option className=' text-primary ' value="2">3</option>
                                            <option className=' text-primary ' value="3">4</option>

                                        </select>
                                    </li>
                                    <li>
                                        <h2 className='text-grey f-f-r-r text-tiny  ' >
                                            OF 102
                                        </h2>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </nav>

                </div>
            </div>

        </div>
    )
}
