import React, { useState, useEffect } from "react";
import CloseIcon from "../../assets/images/Frame 98.svg";
import {
  useSupplier,
  useRemoveGasPrice,
  useAddGasPrice,
} from "../../api/index";

export default function EditPricesDialog() {
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [sizeToRemove, setSizeToRemove] = useState(null);

  const {
    addGasPrice,
    result: resultAdding,
    loading: loadingAdding,
    error: errorAdding,
  } = useAddGasPrice();

  const handleSubmit = () => {
    addGasPrice(size, price);
    if (!loadingAdding && !errorAdding) {
      setSize("");
      setPrice("");
    }
  };

  const { supplier, error, loading } = useSupplier();

  const {
    result,
    loading: removing,
    error: removeError,
  } = useRemoveGasPrice(sizeToRemove);

  useEffect(() => {
    if (result) {
      setSizeToRemove(null); // Reset size to remove
    }
  }, [result]);

  const handleRemoveClick = (size) => {
    setSizeToRemove(size);
  };

  return (
    <dialog
      id="edit_prices_modal"
      className="modal text-start custom-scrollbar"
    >
      <div className="modal-box sm:min-w-[631px] createdlg p-4 sm:p-[30px] ">
        <h3 className="  text-grey f-f-sm-r text-2xl sm:text-3xl ">
          Edit Gas Prices
        </h3>

        {error && <p>Error: {error}</p>}
        {!loading &&
          supplier &&
          Object.entries(supplier.prices).map(([weight, price]) => (
            <div key={weight} className="flex items-center gap-3">
              <div className="flex items-center justify-between cursor-pointer rounded-md hover:bg-blue-dark mt-4 p-2 w-full">
                <h6 className="f-f-m-r text-sm sm:text-base text-grey">
                  {weight}
                </h6>
                <p>R{price}</p>
              </div>
              <button
                onClick={() => handleRemoveClick(weight)}
                className="text-sm text-red-500 hover:underline"
                disabled={removing}
              >
                {removing ? "Removing..." : "Remove"}
              </button>
            </div>
          ))}

        <div className="flex items-center gap-3 mt-4">
          <div className="w-full">
            <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
              Size
            </h6>
            <input
              type="text"
              value={size}
              onChange={(e) => setSize(e.target.value)}
              className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
              placeholder="e.g. 5kg"
            />
          </div>
          <div className="w-full">
            <h6 className="mt-[30px] f-f-m-r text-sm sm:text-base text-grey">
              Price
            </h6>
            <input
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              type="number"
              className="w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none"
              placeholder="e.g. 100"
            />
          </div>
        </div>
        <div className="flex justify-end mt-3">
          <button
            disabled={loadingAdding}
            onClick={() => {
              handleSubmit();
            }}
            className="btn bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light hover:bg-primary-dark"
          >
            Add
          </button>
        </div>

        <form method="dialog">
          <button className=" absolute top-[30px] right-[26px] ">
            <img src={CloseIcon} className=" w-[22px] h-[22px]" />
          </button>
        </form>
      </div>
    </dialog>
  );
}
