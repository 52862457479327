import React, { useState } from "react";
import CloseIcon from "../../assets/images/Frame 98.svg";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { signupDriver, useSupplier } from "../../api";

export default function CreateDriverDialog() {
  const [passVisible, setPassVisible] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { supplier, loading: supplierLoading, error } = useSupplier();

  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");

  const showToast = (msg, type) => {
    setToastType(type);
    setToastMsg(msg);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 4000);
  };

  const signUp = async () => {
    setLoading(true);
    try {
      const response = await signupDriver(
        name,
        phone,
        email,
        password,
        supplier._id
      );
      if (response.success) {
        console.log("Driver Account created");
        showToast("Driver Account created", "alert-success");
        setName("");
        setPhone("");
        setEmail("");
        setPassword("");
        setLoading(false);
      } else {
        console.log(response.error);
        showToast(response.error, "alert-error");
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      showToast(e, "alert-error");
      setLoading(false);
    }
  };

  return (
    <div>
      <dialog
        id="create_driver_modal"
        className="modal text-start custom-scrollbar"
      >
        {toast === true ? (
          <div className="toast toast-center toast-top">
            <div className={`alert ${toastType} text-white`}>{toastMsg}</div>
          </div>
        ) : null}

        <div className="modal-box sm:min-w-[631px] createdlg p-4 sm:p-[30px] ">
          <h3 className="  text-grey f-f-sm-r text-2xl sm:text-3xl ">
            Create Driver
          </h3>

          <div className="flex flex-col gap-6 mt-6">
            <div>
              <p className="f-f-m-r text-sm sm:text-base text-grey">
                Full Name
              </p>
              <input
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                className="input input-bordered w-full mt-2 bg-transparent"
                type="text"
                placeholder="Enter name"
              />
            </div>
            <div>
              <p className="f-f-m-r text-sm sm:text-base text-grey">
                Phone Number
              </p>
              <input
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                className="input input-bordered w-full mt-2 bg-transparent"
                type="text"
                placeholder="Enter phone number"
              />
            </div>
            <div>
              <p className="f-f-m-r text-sm sm:text-base text-grey">Email</p>
              <input
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="input input-bordered w-full mt-2 bg-transparent"
                type="email"
                placeholder="Enter email"
              />
            </div>

            <div>
              <div className="text-white">Password</div>
              <div className="input input-bordered bg-transparent flex items-center gap-2 mt-2">
                <input
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type={`${passVisible ? "text" : "password"}`}
                  placeholder="Enter your password"
                  className="w-full"
                />
                <button
                  onClick={() => {
                    setPassVisible(!passVisible);
                  }}
                >
                  {passVisible ? <LuEye size={22} /> : <LuEyeOff size={22} />}
                </button>
              </div>
            </div>

            <button
              disabled={loading}
              onClick={signUp}
              className="btn btn-block bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light hover:bg-primary-dark"
            >
              Create Account
            </button>
          </div>

          <form method="dialog">
            <button className=" absolute top-[30px] right-[26px] ">
              {" "}
              <img src={CloseIcon} className=" w-[22px] h-[22px]  " />{" "}
            </button>
          </form>
        </div>
      </dialog>
    </div>
  );
}
