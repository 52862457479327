import React, { useState, useEffect } from "react";
import arrowBack from "../../assets/images/arrowack.svg";
import arrowForward from "../../assets/images/arrowforwed.svg";
import { useSupplierUsersTanks } from "../../api";

export default function Table() {
  const { tanks, loading, error } = useSupplierUsersTanks();
  const [currentPage, setCurrentPage] = useState(1);
  const [tanksPerPage] = useState(10); // Adjust the number of tanks per page as needed

  // Get current tanks
  const indexOfLastTank = currentPage * tanksPerPage;
  const indexOfFirstTank = indexOfLastTank - tanksPerPage;
  const currentTanks = tanks.slice(indexOfFirstTank, indexOfLastTank);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(tanks.length / tanksPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleClick = (event) => {
    setCurrentPage(Number(event.target.id));
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = pageNumbers.map((number) => {
    return (
      <li key={number}>
        <a
          href="#"
          id={number}
          onClick={handleClick}
          className={`flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark ${
            currentPage === number ? "text-blue-600 border-blue-dark" : ""
          }`}
        >
          {number}
        </a>
      </li>
    );
  });

  return (
    <div className="">
      <div className="h-screen p-[20px] sm:p-[34px]">
        <h1 className="f-f-m-p text-3xl text-grey text-center sm:text-start">
          Cylinders
        </h1>

        <div className="border border-blue-dark rounded mt-8">
          <div className="table-bg relative overflow-x-auto rounded">
            <table className="w-full">
              <thead className="border-b border-blue-dark">
                <tr className="text-left">
                  <th className="pl-5 py-[10px] f-f-sm-r text-tiny text-grey min-w-[175px] lg:min-w-auto 3xl:min-w-[246px]">
                    Mac Address
                  </th>
                  <th className="px-4 py-[10px] f-f-sm-r text-tiny text-grey min-w-[100px] lg:min-w-auto 3xl:min-w-[184px]">
                    Level (%)
                  </th>
                  <th className="px-4 py-[10px] f-f-sm-r text-tiny text-grey min-w-[110px] lg:min-w-auto 3xl:min-w-[184px]">
                    Battery (%)
                  </th>
                  <th className="px-4 py-[10px] f-f-sm-r text-tiny text-grey min-w-[100px] lg:min-w-auto 3xl:min-w-[184px]">
                    Size (Kg)
                  </th>
                  <th className="px-4 py-[10px] f-f-sm-r text-tiny text-grey min-w-[100px] lg:min-w-auto 3xl:min-w-[184px]">
                    Alarm
                  </th>
                  <th className="px-4 py-[10px] f-f-sm-r text-tiny text-grey min-w-[175px] lg:min-w-auto 3xl:min-w-[184px]">
                    Tank Owner
                  </th>
                </tr>
              </thead>
              {!error && !loading ? (
                <tbody>
                  {currentTanks.map((tank) => (
                    <tr
                      className="border-b border-blue-dark items-center"
                      key={tank.mac}
                    >
                      <td className="pl-5 py-4 f-f-r-r text-base text-grey-light">
                        {tank.mac}
                      </td>
                      <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
                        {tank.level}
                      </td>
                      <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
                        {tank.battery}
                      </td>
                      <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
                        {tank.size}
                      </td>
                      <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
                        {tank.alarm}
                      </td>
                      <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
                        {tank.ownerDetails.name}
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
          </div>

          <nav
            className="w-full py-4 sm:px-[34px]"
            aria-label="Table navigation"
          >
            <div className="grid grid-cols-12 items-center">
              <div className="col-span-12 sm:col-span-6">
                <ul className="inline-flex -space-x-px rtl:space-x-reverse h-[36px]">
                  <li>
                    <a
                      href="#"
                      onClick={handlePrevious}
                      className="flex items-center justify-center px-3 h-[36px] ms-0 leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-l-[4px]"
                    >
                      <ul className="inline-flex">
                        <li>
                          <img
                            src={arrowBack}
                            className="w-[14px] h-[16px]"
                            alt="Previous"
                          />
                        </li>
                        <li className="ml-[3px]">Previous</li>
                      </ul>
                    </a>
                  </li>
                  {renderPageNumbers}
                  <li>
                    <a
                      href="#"
                      onClick={handleNext}
                      className="flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-r-[4px]"
                    >
                      <ul className="inline-flex">
                        <li>Next</li>
                        <li className="ml-[3px]">
                          <img
                            src={arrowForward}
                            className="w-[14px] h-[16px]"
                            alt="Next"
                          />
                        </li>
                      </ul>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-span-12 sm:col-span-6 text-center sm:text-end mt-4 sm:mt-0">
                <ul className="inline-flex space-x-4 items-center">
                  <li>
                    <h2 className="text-grey f-f-r-r text-tiny">PAGE</h2>
                  </li>
                  <li>
                    <select
                      className="rounded-sm outline-none w-[59px] h-[36px] bg-transparent border border-grey-dark text-grey f-f-r-r text-tiny px-3"
                      value={currentPage}
                      onChange={(e) => setCurrentPage(Number(e.target.value))}
                    >
                      {pageNumbers.map((number) => (
                        <option
                          key={number}
                          className="text-primary"
                          value={number}
                        >
                          {number}
                        </option>
                      ))}
                    </select>
                  </li>
                  <li>
                    <h2 className="text-grey f-f-r-r text-tiny">
                      OF {pageNumbers.length}
                    </h2>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}
