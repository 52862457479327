import React from "react";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Table from "./table";

export default function Cylinders() {
  return (
    <div>
      <div className=" hidden xl:block">
        <Sidebar />
      </div>
      <div className="xl:ml-[270px]">
        <Header />
        <Table />
      </div>
    </div>
  );
}
