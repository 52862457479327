import { useState, useEffect, useCallback } from "react";

const baseUrl = "https://app.linksensi.com";

const useSupplier = () => {
  const [supplier, setSupplier] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSupplier = useCallback(async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      setLoading(false);
      setError("No access token found");
      return;
    }

    try {
      const response = await fetch(`${baseUrl}/suppliers/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch supplier data");
      }

      const data = await response.json();
      setSupplier(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  fetchSupplier();

  return { supplier, loading, error };
};

const useTotalUsers = () => {
  const [totalUsers, setTotalUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        setLoading(false);
        setError("No access token found");
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/suppliers/totalusers`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch total users");
        }

        const data = await response.json();
        setTotalUsers(data.totalusers);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalUsers();
  }, []);

  return { totalUsers };
};

const useTotalTanks = () => {
  const [totalTanks, setTotalTanks] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTotalTanks = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        setLoading(false);
        setError("No access token found");
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/suppliers/totaltanks`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch total tanks");
        }

        const data = await response.json();
        setTotalTanks(data.totaltanks);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTotalTanks();
  }, []);

  return { totalTanks };
};

const useTankCountsBySize = () => {
  const [tankCounts, setTankCounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTankCountsBySize = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        setLoading(false);
        setError("No access token found");
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/suppliers/tankcounts`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch tank counts");
        }

        const data = await response.json();
        setTankCounts(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTankCountsBySize();
  }, []);

  return { tankCounts };
};

const useActiveUsers = () => {
  const [activeUsers, setActiveUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/activeusers`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setActiveUsers(data.activeusers || 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchActiveUsers();
  }, []);

  return { activeUsers, loading, error };
};

const useInactiveUsers = () => {
  const [inactiveUsers, setInactiveUsers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchInactiveUsers = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/inactiveusers`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setInactiveUsers(data.inactiveusers || 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInactiveUsers();
  }, []);

  return { inactiveUsers, loading, error };
};

const useSupplierOrders = (filter = "") => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(
          `${baseUrl}/suppliers/orders${filter ? `?filter=${filter}` : ""}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const data = await response.json();
        setOrders(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [filter]);

  return { orders, loading, error };
};

const useSupplierUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/users`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setUsers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading, error };
};

const useSupplierDrivers = () => {
  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDrivers = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/drivers`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();
        setDrivers(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDrivers();
  }, []);

  return { drivers, loading, error };
};

const useUserDetails = (userId) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/users/${userId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user details");
        }

        const data = await response.json();
        setUser(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  return { user, loading, error };
};

const useDriverDetails = (driverId) => {
  const [driver, setDriver] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDriverDetails = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/drivers/${driverId}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch driver details");
        }

        const data = await response.json();
        setDriver(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDriverDetails();
  }, [driverId]);

  return { driver, loading, error };
};

const useSupplierUsersTanks = () => {
  const [tanks, setTanks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTanks = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/tanks`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch tanks");
        }

        const data = await response.json();
        setTanks(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTanks();
  }, []);

  return { tanks, loading, error };
};

const useSupplierSales = (month = "") => {
  const [totalSales, setTotalSales] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSales = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const query = month ? `?month=${month}` : "";
        const response = await fetch(`${baseUrl}/suppliers/sales${query}`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch sales");
        }

        const data = await response.json();
        setTotalSales(data.totalSales || 0);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSales();
  }, [month]);

  return { totalSales, loading, error };
};

const useSalesPerTankSize = () => {
  const [salesPerTankSize, setSalesPerTankSize] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalesPerTankSize = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const response = await fetch(`${baseUrl}/suppliers/salespertanksize`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch sales per tank size");
        }

        const data = await response.json();
        setSalesPerTankSize(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesPerTankSize();
  }, []);

  return { salesPerTankSize, loading, error };
};

const useSalesKgsPerMonth = (month) => {
  const [salesKgs, setSalesKgs] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSalesKgs = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        let url = `${baseUrl}/suppliers/saleskgs`;

        if (month) {
          url += `?month=${month}`;
        }

        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch total sales in kilograms");
        }

        const data = await response.json();
        setSalesKgs(data.totalSalesInKgs);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesKgs();
  }, [month]);

  return { salesKgs, loading, error };
};

const useRemoveGasPrice = (size) => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const removeGasPrice = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        setLoading(false);
        setError("No access token found");
        return;
      }

      if (!size) {
        setLoading(false);
        setError("Size is required");
        return;
      }

      try {
        const response = await fetch(`${baseUrl}/suppliers/gas-prices`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ size }),
        });

        if (!response.ok) {
          throw new Error("Failed to remove gas price");
        }

        const data = await response.text(); // Assuming the response is a text message
        setResult(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    removeGasPrice();
  }, [size]);

  return { result, loading, error };
};

const useAddGasPrice = () => {
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const addGasPrice = useCallback(async (size, price) => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      setError("No access token found");
      return;
    }

    if (!size || price === undefined) {
      setError("Size and price are required");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${baseUrl}/suppliers/gas-prices`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ size, price }),
      });

      if (!response.ok) {
        throw new Error("Failed to add gas price");
      }

      const data = await response.text(); // Assuming the response is a text message
      setResult(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  return { addGasPrice, result, loading, error };
};

//Drivers Section

const signupDriver = async (name, phoneNumber, email, password, supplierId) => {
  try {
    const response = await fetch(`${baseUrl}/drivers/auth/signup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        phoneNumber,
        email,
        password,
        supplierId,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.msg);
    }

    return { success: true, data };
  } catch (err) {
    return { success: false, error: err.message };
  }
};

const useDeleteDriver = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    setError("No access token found");
    return;
  }

  const deleteDriver = async (driverId) => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(`${baseUrl}/drivers/${driverId}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Failed to delete driver account");
      }

      const data = await response.json();
      setResult(data);
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return { deleteDriver, loading, error, result };
};

const useUpdateOrderStatus = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    setError("No access token found");
    return;
  }

  const updateOrderStatus = async (orderId, orderStatus) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${baseUrl}/orders/${orderId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ orderStatus }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to update order status");
      }

      const result = await response.json();
      setSuccess(true);
      return { success: true, result };
    } catch (err) {
      setError(err.message || "Something went wrong");
      return { success: false, error: err.message };
    } finally {
      setLoading(false);
    }
  };

  return { updateOrderStatus, loading, error, success };
};

const useUpdateOrderDriver = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const accessToken = localStorage.getItem("accessToken");
  if (!accessToken) {
    setError("No access token found");
    return;
  }

  const updateOrderDriver = async (orderId, orderDriverId) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const response = await fetch(`${baseUrl}/orders/${orderId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ _driverId: orderDriverId }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || "Failed to update order status");
      }

      const result = await response.json();
      setSuccess(true);
      return { success: true, result };
    } catch (err) {
      setError(err.message || "Something went wrong");
      return { success: false, error: err.message };
    } finally {
      setLoading(false);
    }
  };

  return { updateOrderDriver, loading, error, success };
};

export {
  useSupplier,
  useTotalUsers,
  useTotalTanks,
  useTankCountsBySize,
  useActiveUsers,
  useInactiveUsers,
  useSupplierOrders,
  useSupplierUsers,
  useUserDetails,
  useSupplierUsersTanks,
  useSupplierSales,
  useSalesPerTankSize,
  useSalesKgsPerMonth,
  useRemoveGasPrice,
  useAddGasPrice,
  signupDriver,
  useSupplierDrivers,
  useDriverDetails,
  useDeleteDriver,
  useUpdateOrderStatus,
  useUpdateOrderDriver,
};
