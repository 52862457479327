import React, { useState } from "react";
import Arrow from "../../assets/images/Arrow.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDriverDetails } from "../../api";
import OrdersTable from "./Orders-Table";

export default function Table() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { driver, loading, error } = useDriverDetails(id);

  return (
    <div className={`p-5 sm:p-[34px] h-screen`}>
      <div className="grid grid-cols-12">
        <div className="col-span-12 sm:col-span-6">
          <button onClick={() => navigate("/managedrivers")}>
            <ul className="inline-flex items-center space-x-5">
              <li>
                <a href="">
                  <img src={Arrow} className="w-[15px] h-[13px]" alt="" />
                </a>
              </li>
              <li className="f-f-m-p text-3xl text-grey text-center sm:text-start">
                {loading ? "Loading..." : driver.name}
              </li>
            </ul>
          </button>
        </div>
      </div>
      <div className="box-set2 mt-10 p-2 md:p-10">
        <h1 className="f-f-sm-r text-3xl text-grey ">
          {loading ? "----" : driver.name}
        </h1>

        <div className="grid grid-cols-12 sm:gap-5 3xl:gap-10 mt-[31px]">
          <div className="col-span-12 3xl:col-span-6">
            {/* inner grid start */}
            <div className="grid grid-cols-12">
              <div className="col-span-4 sm:col-span-6">
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light">
                  Email
                </h4>
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                  Phone
                </h4>
              </div>

              <div className="col-span-8 sm:col-span-6">
                <div className="text-end">
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey">
                    {loading ? "----" : driver.email}
                  </h4>
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey mt-5">
                    {loading ? "----" : driver.phoneNumber}
                  </h4>
                </div>
              </div>
            </div>
            {/* inner grid end */}
          </div>

          <div className="col-span-12 3xl:col-span-6">
            {/* inner grid start */}
            <div className="grid grid-cols-12 mt-5 sm:mt-0">
              <div className="col-span-5 sm:col-span-6">
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light">
                  Driver Id
                </h4>
                <h4 className="f-f-m-p text-[11px] sm:text-xl text-grey-light mt-5">
                  Account Creation
                </h4>
              </div>

              <div className="col-span-7 sm:col-span-6">
                <div className="text-end">
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey">
                    {loading ? "----" : driver._id}
                  </h4>
                  <h4 className="f-f-m-p text-[11px] sm:text-2xl text-grey mt-5">
                    {loading ? "----" : driver.accountCreationDate}
                  </h4>
                </div>
              </div>
            </div>
            {/* inner grid end */}
          </div>
        </div>
      </div>
      <div className="mt-12">
        <h1 className="f-f-m-p text-grey text-center sm:text-start">
          Orders Assigned
        </h1>
      </div>
      <OrdersTable orders={loading ? [] : driver.orders_assigned} />
    </div>
  );
}
