import React from "react";
import mapsIcon from "../../assets/images/google_maps_icon.svg";
import { Link } from "react-router-dom";

export default function OrderDrawer({ setIsOpen, isOpen, order }) {
  const handleOpenGoogleMaps = () => {
    const latitude = order.location.lat;
    const longitude = order.location.lng;

    // Construct the Google Maps URL
    const googleMapsUrl = `https://www.google.com/maps?q=${latitude},${longitude}&z=15`;

    // Open the URL in a new tab
    window.open(googleMapsUrl, "_blank");
  };

  return (
    <div>
      <div className={`drawer drawer-end z-10 ${isOpen ? "open" : ""}`}>
        <input
          id="my-drawer-4"
          type="checkbox"
          className="drawer-toggle"
          checked={isOpen}
          onChange={() => setIsOpen(!isOpen)}
        />
        <div className="drawer-content">
          {/* <label htmlFor="my-drawer-4" className="drawer-button">   <h1 className='f-f-m-p text-3xl text-grey my-10'>Cylinders</h1></label> */}
        </div>
        <div className="drawer-side">
          <label
            htmlFor="my-drawer-4"
            aria-label="close sidebar"
            className="drawer-overlay"
          ></label>
          <div className="menu p-5 sm:p-10 w-11/12 md:w-[612px] min-h-full  createdlg-cylinder  ">
            <h1 className="f-f-m-r text-3xl text-grey">Order Details</h1>

            <div className="inline-flex items-center py-[10px] mt-10 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p  text-sm sm:text-base text-grey-light">
                Total Cost
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.totalCost}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Cylinder Size
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.cylinderSize} kg
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Payment Type
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.paymentType}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Time Placed
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.timePlaced}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Order Id
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.orderId}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Status
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey flex items-center gap-3">
                {order.status}

                <button
                  onClick={() =>
                    document
                      .getElementById("edit_order_status_modal")
                      .showModal()
                  }
                  className="btn btn-outline btn-sm text-white hover:bg-primary-dark hover:border-none hover:text-white"
                >
                  Edit
                </button>
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Delivery Date
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.deliveryDate}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Delivery Time
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.deliveryTime}
              </h2>
            </div>

            <div className="sm:inline-flex items-center py-[10px] mt-5 sm:justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Location
              </h1>
              <h2>
                <button
                  className="bg-white text-blue-dark p-2 flex items-center gap-3 rounded-md"
                  onClick={handleOpenGoogleMaps}
                >
                  <img className="h-5" src={mapsIcon} />
                  Open on Maps
                </button>
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Driver
              </h1>

              {order.driverId ? (
                <Link
                  to={`/managedrivers/${order.driverId}`}
                  className="f-f-m-r text-base sm:text-xl text-grey hover:underline"
                >
                  {order.driverName}
                </Link>
              ) : (
                <button
                  onClick={() =>
                    document.getElementById("add_driver_modal").showModal()
                  }
                  className="btn btn-outline btn-sm text-white hover:bg-primary-dark hover:border-none hover:text-white"
                >
                  Add
                </button>
              )}
            </div>

            <div className="py-[10px] mt-5 w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Comments
              </h1>
              <h2 className="f-f-m-r mt-3 text-base sm:text-xl text-grey">
                {order.comments}
              </h2>
            </div>

            <h1 className="f-f-m-r text-3xl text-grey my-10">
              Customer Details
            </h1>

            <div className="inline-flex items-center py-[10px] justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Full Name
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.customerFullName}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Email
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.customerEmail}
              </h2>
            </div>

            <div className="inline-flex items-center py-[10px] mt-5 justify-between w-full border-b border-blue-dark ">
              <h1 className="f-f-m-p text-sm sm:text-base text-grey-light">
                Phone
              </h1>
              <h2 className="f-f-m-r text-base sm:text-xl text-grey">
                {order.customerPhone}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
