import React from 'react'
import Account from '../../assets/images/Account.svg'
import Billing from '../../assets/images/billing.svg'
import Notification from '../../assets/images/notification.svg'
import Kala from '../../assets/images/Kala.svg'


export default function table() {
    return (
        <div className='p-[20px] sm:p-[34px]'>

            <h1 className='f-f-m-p text-3xl text-grey mt-[14px] mb-[30px] '>Setting</h1>

            <div className="flex-wrap items-center space-x-[55px]">

                <button className='f-f-r-p text-xl text-primary-light'>
                    <ul className='inline-flex items-center space-x-[10px]'>
                        <li><img src={Account} className='w-6 h-6' alt="" /></li>
                        <li>Account</li>
                    </ul>
                </button>

                <button className='f-f-r-p text-xl text-grey-light'>
                    <ul className='inline-flex items-center space-x-[10px]'>
                        <li><img src={Billing} className='w-6 h-6' alt="" /></li>
                        <li>Billing</li>
                    </ul>
                </button>

                <button className='f-f-r-p text-xl text-grey-light'>
                    <ul className='inline-flex items-center space-x-[10px]'>
                        <li><img src={Notification} className='w-6 h-6' alt="" /></li>
                        <li>Notification</li>
                    </ul>
                </button>
            </div>

            <div className="mt-[42px] border border-blue-dark rounded-lg px-5 lg:px-10 pt-6 pb-10 lg:pb-[92px]">
                <ul className='sm:inline-flex items-center  sm:justify-between w-full'>
                    <li className='sm:text-start text-center'>
                        {/* inner ul start */}
                        <ul className='inline-flex items-center space-x-5'>
                            <li><img src={Kala} className='w-[60px] h-[60px]' alt="" /></li>
                            <li className='f-f-m-p text-2xl text-grey'>Carlie John</li>
                        </ul>
                        {/* inner ul end  */}
                    </li>

                    <li>
                        <button className='mt-3 sm:mt-0 w-full sm:w-[220px] h-[40px] sm:h-[52px] rounded-lg border border-primary-dark f-f-r-p text-base sm:text-xl text-primary-light '>Upload new picture</button>
                    </li>
                </ul>

                <h1 className='f-f-m-p text-xl text-grey  mt-[30px]'>Basic Information</h1>
                <h3 className='f-f-r-p text-base text-grey-vlight mt-[6px]'>Lorem Ipsum is simply dummy text.</h3>

                <div className="grid grid-cols-12 sm:gap-5 lg:gap-10">
                    <div className="col-span-12 xl:col-span-6">


                        {/* inner grid start */}
                        <div className="grid grid-cols-12 gap-5 lg:gap-[30px] mt-6">
                            <div className="col-span-12 sm:col-span-6">
                                <h1 className='f-f-r-p text-sm sm:text-base text-grey '>Full Name</h1>
                                <input type="text" className='w-full h-[40px] sm:h-[48px] rounded-lg border border-blue-dark mt-3 bg-transparent f-f-r-p text-[11px] sm:text-base text-grey pl-5 outline-none' placeholder='Carlie John' />
                            </div>

                            <div className="col-span-12 sm:col-span-6">
                                <h1 className='f-f-r-p text-sm sm:text-base text-grey '>Email Address</h1>
                                <input type="text" className='w-full h-[40px] sm:h-[48px] rounded-lg border border-blue-dark mt-3 bg-transparent f-f-r-p text-[11px] sm:text-base text-grey pl-5 outline-none' placeholder='Example@gmail.com' />
                            </div>
                        </div>
                        {/* inner grid end  */}
                        <div className="hidden xl:block ">

                            <h1 className='f-f-r-p text-sm sm:text-base text-grey mt-6 '>About Biography</h1>
                            <textarea name="" id="" className="mt-3 border border-blue-dark bg-transparent rounded-lg p-4 w-full h-[182px] f-f-r-p text-sm sm:text-base text-grey outline-none ">

                            </textarea>

                            <button className='w-[181px] h-[52px] mt-8 rounded-lg bg-primary-light f-f-r-p text-xl text-grey'>Save Changes</button>
                        </div>

                    </div>

                    <div className="col-span-12 xl:col-span-6">

                        {/* inner grid start */}
                        <div className="grid grid-cols-12 gap-5 lg:gap-[30px] mt-6">
                            <div className="col-span-12 sm:col-span-6">
                                <h1 className='f-f-r-p text-sm sm:text-base text-grey '>Phone Number</h1>
                                <input type="text" className='w-full h-[40px] sm:h-[48px] rounded-lg border border-blue-dark mt-3 bg-transparent f-f-r-p text-[11px] sm:text-base text-grey pl-5 outline-none' placeholder='Example@gmail.com' />
                                <h1 className='f-f-r-p text-sm sm:text-base text-grey mt-6 '>Location</h1>
                                <input type="text" className='w-full h-[40px] sm:h-[48px] rounded-lg border border-blue-dark mt-3 bg-transparent f-f-r-p text-[11px] sm:text-base text-grey pl-5 outline-none' placeholder='Your Location' />
                            </div>

                            <div className="col-span-12 sm:col-span-6">
                                <h1 className='f-f-r-p text-sm sm:text-base text-grey '>Role</h1>
                                <input type="text" className='w-full h-[40px] sm:h-[48px] rounded-lg border border-blue-dark mt-3 bg-transparent f-f-r-p text-[11px] sm:text-base text-grey pl-5 outline-none' placeholder='Example@gmail.com' />

                                <h1 className='f-f-r-p text-sm sm:text-base text-grey mt-6 '>Website</h1>
                                <input type="text" className='w-full h-[40px] sm:h-[48px] rounded-lg border border-blue-dark mt-3 bg-transparent f-f-r-p text-[11px] sm:text-base text-grey pl-5 outline-none' placeholder='Example@gmail.com' />
                            </div>
                        </div>
                        {/* inner grid end  */}

                        <div className="xl:hidden  ">

                            <h1 className='f-f-r-p text-sm sm:text-base text-grey mt-6 '>About Biography</h1>
                            <textarea name="" id="" className="mt-3 border border-blue-dark bg-transparent rounded-lg p-4 w-full h-[140px] sm:h-[182px] f-f-r-p text-sm sm:text-base text-grey outline-none ">

                            </textarea>

                            <button className='w-full sm:w-[181px] h-[40px] sm:h-[52px] mt-8 rounded-lg bg-primary-light f-f-r-p text-base sm:text-xl text-grey'>Save Changes</button>
                        </div>

                    </div>
                </div>
            </div>


        </div>
    )
}
