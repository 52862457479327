import React from 'react'
import Debit from '../../assets/images/debit.svg'
import { Link } from 'react-router-dom'




export default function table() {
  return (
    <div className='p-[20px] sm:p-[34px]'>

      <h1 className='f-f-m-p text-3xl text-grey text-center md:text-start mt-[14px]'>Withdraw Money</h1>

      <div className="withdraw-set rounded-[15px]  mt-[26px] h-auto lg:h-[278px] w-full p-5 sm:p-10 lg:p-[43px] md:inline-flex md:justify-between">
        <div className="text-center md:text-start">
          <h1 className='f-f-sm-p text-4xl sm:text-[28px] lg:text-6xl text-grey'>Available Balance</h1>
          <h2 className='f-f-sm-p text-6xl sm:text-[50px] lg:text-[64px] text-grey mt-[6px] '>$300</h2>
          <h3 className='f-f-r-p text-3xl sm:text-4xl text-grey'>+0.00$ Pending</h3>
        </div>
        <div className="mt-4 md:mt-auto">
          <Link to="/setup" >
            <button className='w-full md:w-[225px] lg:w-[249px] h-11 sm:h-[45px] lg:h-[54px] border border-grey rounded-[10px] f-f-r-p text-2xl sm:text-3xl lg:text-4xl text-grey '>Withdraw Amount</button>
          </Link>
        </div>

      </div>


      <div className="grid grid-cols-12 gap-5 sm:gap-10 mt-10">
        <div className="col-span-12 2xl:col-span-6">
          <div className="box-set2 p-6 sm:p-[30px] h-auto 2xl:h-[221px] w-full">

            <ul className='inline-flex items-center justify-between w-full'>
              <li className='f-f-sm-r text-3xl sm:text-4xl text-grey'>Withdraw Schedule</li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
                  <path d="M16.3777 4.01982C16.5603 4.20289 16.7096 4.41924 16.8258 4.66887C16.9419 4.9185 17 5.1931 17 5.49266C17 5.75893 16.9502 6.01689 16.8507 6.26652C16.7511 6.49951 16.6183 6.70754 16.4524 6.8906L6.89458 16.4765C6.72865 16.6429 6.52953 16.7844 6.29722 16.9009C6.0815 17.0007 5.84919 17.059 5.60029 17.0756L1.16984 17.5H1.07028C0.771596 17.5 0.514397 17.4001 0.298682 17.2004C0.0995608 16.9841 0 16.7261 0 16.4266C0 16.4099 0 16.3933 0 16.3767C0.0165935 16.36 0.0248902 16.3434 0.0248902 16.3267L0.472914 11.8833C0.506101 11.6336 0.572474 11.4006 0.672035 11.1843C0.771596 10.9679 0.904344 10.7766 1.07028 10.6101L10.6281 1.02423C10.8106 0.857807 11.0181 0.732991 11.2504 0.64978C11.4993 0.549927 11.7482 0.5 11.9971 0.5C12.2792 0.5 12.5447 0.558248 12.7936 0.674743C13.0425 0.774596 13.2665 0.916055 13.4656 1.09912L16.3777 4.01982ZM12.7687 7.56461L14.8843 5.49266L11.9971 2.57195L9.9063 4.7188L12.7687 7.56461Z" fill="#EEF0FC" />
                </svg></li>
            </ul>

            <h3 className='mt-[30px] f-f-m-p text-3xl sm:text-4xl text-grey '>Quarterly (next on june 24)</h3>
            <h3 className='mt-[10px] f-f-m-p text-base text-grey-light '>Only when balance is $100.00 or more</h3>
            <h3 className='mt-[20px] f-f-m-p text-xl sm:text-3xl text-grey '>Direct to local bank - Account ending in 4199 </h3>

          </div>
        </div>
        <div className="col-span-12 2xl:col-span-6">
          <div className="box-set2 p-6 sm:p-[30px] h-auto 2xl:h-[221px] w-full">



            <h3 className='f-f-sm-r text-3xl sm:text-4xl text-grey '>Last Withdrawl</h3>
            <h3 className=' mt-5 sm:mt-[30px] f-f-m-p text-xl text-grey-light '>You haven’t made any withdrawls yet</h3>


          </div>
        </div>
      </div>

      <div className="box-set2 p-4 sm:p-8 h-auto md:h-[221px] w-full mt-10 ">
        <ul className='inline-flex items-center justify-between w-full'>
          <li className='f-f-sm-r text-3xl sm:text-4xl text-grey'>Withdrawl Method</li>
          <li><button className='w-[105px] sm:w-[115px] h-[25px] sm:h-[29px] rounded-[10px] bg-primary-dark f-f-sm-n text-sm sm:text-base text-grey '>Add a Method</button>
          </li>
        </ul>

        <ul className='md:inline-flex items-center md:justify-between w-full mt-10'>
          <li>
            {/* inner ul start */}
            <ul className='inline-flex space-x-3  sm:space-x-5'>
              <li><img src={Debit} className='w-[35px] sm:w-[46px] h-[35px] sm:h-[46px] ' alt="" /></li>
              <li className='f-f-m-p text-[11px] sm:text-xl text-grey mt-1 sm:mt-3'>
                <h1>Direct to Local Bank- Account Ending in 4199</h1>
                <button className='w-[190px] sm:w-[202px] h-7 rounded-[10px] border border-grey f-f-r-p text-xs sm:text-sm text-grey mt-2 sm:mt-4'>Waiting for Security Clearence</button>
              </li>
            </ul>
            {/* inner ul end */}
          </li>
          <li>
            {/* inner ul start */}
            <ul className='inline-flex items-center space-x-5 sm:space-x-[30px]  w-full justify-end sm:mt-0 mt-2'>
              <li className='f-f-m-p text-xl sm:text-3xl text-green'><a href="">Edit</a></li>
              <li className='f-f-m-p text-xl sm:text-3xl text-red'><a href="">Delete</a></li>
            </ul>
            {/* inner ul end */}
          </li>
        </ul>
      </div>




    </div>
  )
}
