import React, { useState } from "react";
import CloseIcon from "../../assets/images/Frame 98.svg";
import { useDeleteDriver } from "../../api/index";

export default function DeleteDriverDialog({ activeDriver }) {
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");

  const showToast = (msg, type) => {
    setToastType(type);
    setToastMsg(msg);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 4000);
  };

  const { deleteDriver, loading, error, result } = useDeleteDriver();

  const handleDelete = async () => {
    await deleteDriver(activeDriver._id);
    if (!error) {
      console.log("Driver Account deleted");
      showToast("Driver Account Deleted", "alert-success");
    }
  };

  return (
    <div>
      <dialog
        id="delete_driver_modal"
        className="modal text-start custom-scrollbar"
      >
        {toast === true ? (
          <div className="toast toast-center toast-top">
            <div className={`alert ${toastType} text-white`}>{toastMsg}</div>
          </div>
        ) : null}

        <div className="modal-box sm:min-w-[631px] createdlg p-4 sm:p-[30px] ">
          <h3 className="  text-grey f-f-sm-r text-2xl sm:text-3xl ">
            Delete Account
          </h3>

          <div className="my-6">
            <p>Are you sure you want to delete this driver account?</p>
            <div className="flex items-center gap-3 mt-2">
              <p className="font-semibold">Full Name:</p>
              <p className="text-white">{activeDriver.name}</p>
            </div>
          </div>

          <button
            disabled={loading}
            onClick={handleDelete}
            className="btn btn-block bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light hover:bg-primary-dark"
          >
            Delete Account
          </button>

          <form method="dialog">
            <button className=" absolute top-[30px] right-[26px] ">
              {" "}
              <img src={CloseIcon} className=" w-[22px] h-[22px]  " />{" "}
            </button>
          </form>
        </div>
      </dialog>
    </div>
  );
}
