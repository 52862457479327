import React from 'react'
import supplier from '../../assets/images/supplier-deatil.svg'
import Bottle from '../../assets/images/bottel.svg'
import Graph from '../../assets/images/graph.svg'
import Linksense from '../../assets/images/LinkSensi white 1.svg'


export default function table() {
    return (
        <div className=' pb-[200px] ' >
            <div className='p-[20px] sm:p-[34px]'>

                <h1 className='f-f-m-p text-3xl text-grey text-center md:text-start mt-3'>Supplier Detail</h1>

                <div className="w-full h-auto 3xl:h-[460px] rounded-[15px] bg-primary p-5 md:p-10 mt-10 md:mt-[37px] ">


                    <div className="grid grid-cols-12 gap-5 md:gap-10">

                        <div className="col-span-12 3xl:col-span-6">
                            {/* inner grid start */}
                            <div className="grid grid-cols-12 gap-7  ">
                                <div className="col-span-12 md:col-span-6">
                                    <img src={supplier} className='w-full md:w-[337px] h-auto md:h-[380px] ' alt="" />
                                </div>

                                <div className="col-span-12 md:col-span-6">
                                    <div className="text-center md:text-start">
                                        <h1 className='f-f-sm-n text-3xl text-grey-light mt-3 '>Name</h1>
                                        <h1 className='f-f-sm-n text-3xl text-white mt-[6px]'>David Miller</h1>

                                        <h1 className='f-f-sm-n text-3xl text-grey-light mt-[30px]'>Email</h1>
                                        <h1 className='f-f-sm-n text-3xl text-white mt-[6px]'>dm@gmail.com</h1>

                                        <h1 className='f-f-sm-n text-3xl text-grey-light mt-[30px]'>Number</h1>
                                        <h1 className='f-f-sm-n text-3xl text-white mt-[6px]'>+0 000 0000000</h1>

                                        <h1 className='f-f-sm-n text-3xl text-grey-light mt-[30px]'>Address</h1>
                                        <h1 className='f-f-sm-n text-3xl text-white mt-[6px]'>loreum epsumloreum epsum</h1>
                                    </div>
                                </div>

                            </div>
                            {/* inner grid end  */}
                        </div>

                        <div className="col-span-12 3xl:col-span-6">

                            {/* inner grid start  */}

                            <div className="grid grid-cols-12 gap-5 md:gap-10 mt-[59px]">

                                <div className="col-span-12 md:col-span-6">
                                    <div className="box-set border border-blue-dark rounded-[15px] w-full h-[127px] py-4 px-6 ">
                                        <div className="inline-flex justify-between w-full items-center">
                                            <div className="">
                                                <h1 className='f-f-sm-p text-6xl text-grey'>30</h1>
                                                <h4 className='f-f-r-p text-sm text-grey'>Total Tanks</h4>
                                            </div>
                                            <div className="">
                                                <img src={Bottle} className='w-[62px] h-[94px] ' alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-span-12 md:col-span-6">
                                    <div className="box-set border border-blue-dark rounded-[15px] w-full h-[127px] py-5 px-6 ">

                                        <div className="">
                                            <h1 className='f-f-sm-p text-3xl text-grey'>Subscription</h1>
                                            <h4 className='f-f-r-p text-sm text-grey'>1500$</h4>
                                        </div>
                                        <div className="text-end mt-2">
                                            <button className='border border-green rounded-[10px] w-[80px] h-[31px] f-f-m-p text-base text-green'>Paid</button>

                                        </div>
                                    </div>

                                </div>

                                <div className="col-span-12 md:col-span-6">
                                    <div className="box-set border border-blue-dark rounded-[15px] w-full h-[127px] py-4 px-6 ">
                                        <div className="inline-flex justify-between w-full items-center">
                                            <div className="">
                                                <h1 className='f-f-sm-p text-6xl text-grey'>04</h1>
                                                <h4 className='f-f-r-p text-sm text-grey'>Completed Orders</h4>
                                            </div>
                                            <div className="">
                                                <img src={Graph} className='w-[89px] h-[89px] ' alt="" />
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="col-span-12 md:col-span-6">
                                    <div className="box-set border border-blue-dark rounded-[15px] w-full h-[127px] py-4 px-6 ">

                                        <div className="">
                                            <h1 className='f-f-sm-p text-3xl text-grey'>Supplier</h1>
                                            <h4 className='f-f-r-p text-sm text-grey'>LinkSensi</h4>
                                        </div>
                                        <div className="  mt-3 w-full">
                                            <img src={Linksense} className='w-[159px] h-[26px] ml-auto ' alt="" />
                                        </div>

                                    </div>

                                </div>

                            </div>

                            {/* inner grid end */}

                        </div>
                    </div>

                </div>






            </div>
        </div>
    )
}
