import React, { useState } from "react";
import CloseIcon from "../../assets/images/Frame 98.svg";
import { useSupplierDrivers, useUpdateOrderDriver } from "../../api/index";
import { IoMdCheckmarkCircle } from "react-icons/io";
import Avatar from "react-avatar";

export default function AddDriverDialog({ orderId }) {
  const [activeDriverId, setActiveDriverId] = useState("");
  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");
  const { drivers } = useSupplierDrivers();

  const showToast = (msg, type) => {
    setToastType(type);
    setToastMsg(msg);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 4000);
  };

  const { updateOrderDriver, loading } = useUpdateOrderDriver();

  const handleUpdate = async () => {
    const { success, error } = await updateOrderDriver(orderId, activeDriverId);
    if (success) {
      showToast("Driver Added", "alert-success");
    } else if (error) {
      showToast("Error adding driver", "alert-error");
    }
  };

  return (
    <dialog id="add_driver_modal" className="modal text-start custom-scrollbar">
      {toast === true ? (
        <div className="toast toast-center toast-top">
          <div className={`alert ${toastType} text-white`}>{toastMsg}</div>
        </div>
      ) : null}

      <div className="modal-box sm:min-w-[631px] createdlg p-4 sm:p-[30px] ">
        <h3 className="  text-grey f-f-sm-r text-2xl sm:text-3xl ">
          Choose Driver
        </h3>

        <p className="py-6">Select a driver to deliver this order.</p>

        {drivers
          ? drivers.map((driver, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    setActiveDriverId(driver._id);
                  }}
                  className="p-3 f-f-r-r flex items-center justify-between rounded-md hover:bg-blue-dark cursor-pointer text-base text-grey-light"
                >
                  <div className="inline-flex items-center space-x-[23px]">
                    <div>
                      <div className="avatar">
                        <Avatar name={driver.name} size="35" round />
                      </div>
                    </div>
                    <div className="f-f-m-r text-base text-grey">
                      {driver.name}
                    </div>
                  </div>
                  {activeDriverId === driver._id ? (
                    <IoMdCheckmarkCircle color="#fff" size={22} />
                  ) : null}
                </div>
              );
            })
          : null}

        <div className="flex justify-end mt-6">
          <button
            disabled={loading}
            onClick={handleUpdate}
            className="btn bg-primary-dark f-f-sm-n text-tiny sm:text-base text-white-light hover:bg-primary-dark"
          >
            Assign
          </button>
        </div>
        <form method="dialog">
          <button className=" absolute top-[30px] right-[26px] ">
            <img src={CloseIcon} className=" w-[22px] h-[22px]" />
          </button>
        </form>
      </div>
    </dialog>
  );
}
