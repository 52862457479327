import React, { useState } from "react";
import Correct from "../../assets/images/correct.svg";
import GreenC from "../../assets/images/greencorrect.svg";
import Processing from "../../assets/images/Icon3 .svg";
import waiting from "../../assets/images/waiting.svg";
import canceled from "../../assets/images/canceled.svg";
import arrowBack from "../../assets/images/arrowack.svg";
import arrowForward from "../../assets/images/arrowforwed.svg";
import { MdEdit } from "react-icons/md";

import { useSupplierOrders } from "../../api";
import EditPricesDialog from "./edit-prices-dlg";
import OrderDrawer from "./order-drawer";
import EditOrderStatusDialog from "./edit-order-status-dlg";
import AddDriverDialog from "./add-driver-dlg";

const TableHeader = () => (
  <thead className="border-b border-blue-dark">
    <tr className="text-left">
      <th className="pl-5 py-[10px] f-f-r-r text-tiny text-grey">Order Id</th>
      <th className="px-4 py-[10px] f-f-r-r text-tiny text-grey">
        Customer Name
      </th>
      <th className="px-4 py-[10px] f-f-r-r text-tiny text-grey">Tank Size</th>
      <th className="px-4 py-[10px] f-f-r-r text-tiny text-grey">Total Cost</th>
      <th className="px-4 py-[10px] f-f-r-r text-tiny text-grey">
        Time Placed
      </th>
      <th className="px-4 py-[10px] f-f-r-r text-tiny text-grey">Status</th>
    </tr>
  </thead>
);

const TableRow = ({ order, onClick }) => (
  <tr
    onClick={onClick}
    className="border-b cursor-pointer border-blue-dark items-center"
  >
    <td className="pl-5 py-4 f-f-m-r text-base text-grey-light">{order._id}</td>
    <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
      {order.customerInfo.name}
    </td>
    <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
      {order.cylinderSize} kg
    </td>
    <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
      {order.price}
    </td>
    <td className="px-4 py-4 f-f-r-r text-base text-grey-light">
      {order.timePlaced}
    </td>
    <td className="px-4 py-4">{order.orderStatus}</td>
  </tr>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <nav className="w-full py-4 sm:px-[34px]" aria-label="Table navigation">
    <div className="grid grid-cols-12 items-center">
      <div className="col-span-12 sm:col-span-6">
        <ul className="inline-flex -space-x-px rtl:space-x-reverse h-[36px]">
          <li>
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-l-[4px]"
            >
              <img
                src={arrowBack}
                className="w-[14px] h-[16px]"
                alt="Previous"
              />
              <span className="ml-[3px]">Previous</span>
            </button>
          </li>
          {Array.from({ length: totalPages }, (_, i) => (
            <li key={i}>
              <button
                onClick={() => onPageChange(i + 1)}
                className={`flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark ${
                  currentPage === i + 1 ? "text-blue-600" : ""
                }`}
              >
                {i + 1}
              </button>
            </li>
          ))}
          <li>
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-[36px] leading-tight text-grey f-f-r-r text-tiny bg-transparent border border-blue-dark rounded-r-[4px]"
            >
              <span>Next</span>
              <img
                src={arrowForward}
                className="w-[14px] h-[16px] ml-[3px]"
                alt="Next"
              />
            </button>
          </li>
        </ul>
      </div>
      <div className="col-span-12 sm:col-span-6 text-center sm:text-end mt-4 sm:mt-0">
        <span className="text-grey f-f-r-r text-tiny">
          PAGE {currentPage} OF {totalPages}
        </span>
      </div>
    </div>
  </nav>
);

export default function Table() {
  const [activeOrder, setActiveOrder] = useState({
    totalCost: "",
    cylinderSize: "",
    paymentType: "",
    timePlaced: "",
    orderId: "",
    status: "",
    deliveryDate: "",
    deliveryTime: "",
    location: {
      lat: "",
      lng: "",
    },
    driverName: "",
    driverId: "",
    comments: "",
    customerFullName: "",
    customerEmail: "",
    customerPhone: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const ordersPerPage = 10;
  const [filter,setFilter] = useState("");
  const { orders, loading, error } = useSupplierOrders(filter);

  const totalPages = Math.ceil((orders?.length || 0) / ordersPerPage);
  const displayedOrders =
    orders?.slice(
      (currentPage - 1) * ordersPerPage,
      currentPage * ordersPerPage
    ) || [];

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <div className="p-[20px] h-screen sm:p-[34px]">
        <div className="mb-[30px]">
          <div className="flex items-center justify-between">
            <h1 className="f-f-m-p text-3xl text-grey">Gas Orders</h1>
            <button
              onClick={() =>
                document.getElementById("edit_prices_modal").showModal()
              }
              className="btn btn-outline btn-sm text-white hover:bg-primary-dark hover:border-none hover:text-white"
            >
              <MdEdit color="white" />
              Edit prices
            </button>
          </div>
          <div className="grid grid-cols-12 mt-3 items-center">
            <div className="col-span-12 md:col-span-10">
              <div className="sm:flex-wrap space-y-3 lg:space-y-0 space-x-5">
                <button onClick={()=>setFilter("")} className="w-[61px] h-[31px] rounded-[10px] bg-primary-dark">
                  <ul className="inline-flex items-center space-x-2">
                    <li>
                      <img
                        src={Correct}
                        className="w-[13px] h-[13px]"
                        alt="All"
                      />
                    </li>
                    <li className="f-f-m-p text-base text-white">All</li>
                  </ul>
                </button>
                <button onClick={()=>setFilter("completed")} className="w-[120px] h-[31px] rounded-[10px] border border-green">
                  <ul className="inline-flex items-center space-x-2">
                    <li>
                      <img
                        src={GreenC}
                        className="w-[13px] h-[13px]"
                        alt="Complete"
                      />
                    </li>
                    <li className="f-f-m-p text-base text-green">Completed</li>
                  </ul>
                </button>
                <button onClick={()=>setFilter("processing")} className="w-[120px] h-[31px] rounded-[10px] border border-blue">
                  <ul className="inline-flex items-center space-x-2">
                    <li>
                      <img
                        src={Processing}
                        className="w-[13px] h-[13px]"
                        alt="Processing"
                      />
                    </li>
                    <li className="f-f-m-p text-base text-blue">Processing</li>
                  </ul>
                </button>
                <button onClick={()=>setFilter("waiting")} className="w-[120px] h-[31px] rounded-[10px] border border-yellow">
                  <ul className="inline-flex items-center space-x-2">
                    <li>
                      <img
                        src={waiting}
                        className="w-[13px] h-[13px]"
                        alt="Waiting"
                      />
                    </li>
                    <li className="f-f-m-p text-base text-yellow">Waiting</li>
                  </ul>
                </button>
                {/* <button className="w-[120px] h-[31px] rounded-[10px] border border-red">
                  <ul className="inline-flex items-center space-x-2">
                    <li>
                      <img
                        src={canceled}
                        className="w-[13px] h-[13px]"
                        alt="Canceled"
                      />
                    </li>
                    <li className="f-f-m-p text-base text-red">Canceled</li>
                  </ul>
                </button> */}
              </div>
            </div>
            <div className="col-span-12 md:col-span-2">
              <div className="text-center sm:text-end mt-3 lg:mt-0">
                <div className="dropdown dropdown-end">
                  <div
                    tabIndex={0}
                    role="button"
                    className="m-1 f-f-r-p text-lg text-grey cursor-pointer"
                  ></div>
                  <ul
                    tabIndex={0}
                    className="mt-3 dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    <li>
                      <a>Item 1</a>
                    </li>
                    <li>
                      <a>Item 2</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-[400px]">
          <div className="border border-blue-dark rounded mt-5">
            <div className="table-bg relative overflow-x-auto rounded rounded-t">
              <table className="w-full">
                <TableHeader />
                {!loading && !error ? (
                  <tbody>
                    {displayedOrders.map((order) => (
                      <TableRow
                        key={order._id}
                        order={order}
                        onClick={() => {
                          setActiveOrder({
                            totalCost: order.price,
                            cylinderSize: order.cylinderSize,
                            paymentType: order.paymentType,
                            timePlaced: order.timePlaced,
                            orderId: order._id,
                            status: order.orderStatus,
                            deliveryDate: order.deliveryDate,
                            deliveryTime: order.deliveryTime,
                            location: {
                              lat: order.address.lat,
                              lng: order.address.lng,
                            },
                            driverName: order.driverInfo
                              ? order.driverInfo.name
                              : "",
                            driverId: order.driverInfo
                              ? order.driverInfo._id
                              : "",
                            comments: order.comments,
                            customerFullName: order.customerInfo.name,
                            customerEmail: order.customerInfo.email,
                            customerPhone: order.customerInfo.phoneNumber,
                          });
                          toggleDrawer();
                        }}
                      />
                    ))}
                  </tbody>
                ) : null}
              </table>
            </div>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </div>
          <OrderDrawer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            order={activeOrder}
          />
        </div>
      </div>
      <EditPricesDialog />
      <EditOrderStatusDialog orderId={activeOrder.orderId} />
      <AddDriverDialog orderId={activeOrder.orderId} />
    </>
  );
}
