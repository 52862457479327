import React, { useEffect } from "react";
import Logo from "../../assets/images/LinkSensi white 1.svg";
import staticsicon from "../../assets/images/staticsicon.svg";
import manageuser from "../../assets/images/manageuser.svg";
import managedriver from "../../assets/images/managedriver.svg";
import managesupillr from "../../assets/images/managesupillr.svg";
import suppiler from "../../assets/images/suppiler.svg";
import gasorder from "../../assets/images/gasorder.svg";
import earning from "../../assets/images/earning.svg";
import Setting from "../../assets/images/Setting.svg";
import Logout from "../../assets/images/Logout.svg";
import cylinders from "../../assets/images/cylinders.svg";
import roless from "../../assets/images/roless.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth, useSignOut } from "../../api/auth";

export default function Sidebar() {
  const { auth, setAuthState } = useAuth();
  const signOut = useSignOut();

  const handleSignOut = async () => {
    try {
      await signOut();
      console.log("Signed out successfully");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const accountType = localStorage.getItem("accountType");
    if (accessToken && accountType) {
      setAuthState(accessToken, accountType);
    }
  }, [setAuthState]);

  const renderNavLink = (to, icon, label) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `${
          isActive ? "bg-blue-dark" : ""
        }  hover:bg-blue-dark rounded-[10px] w-full p-4`
      }
    >
      <ul className="inline-flex items-center text-start">
        <li>
          <img src={icon} className="w-[18px] h-[18px]" />
        </li>
        <li className="f-f-sm-p text-tiny 2xl:text-lg text-grey ml-[15px]">
          {label}
        </li>
      </ul>
    </NavLink>
  );

  const renderSidebarContent = () => {
    switch (auth.accountType) {
      case "supplier":
        return (
          <div className="flex flex-col gap-3">
            {renderNavLink("/", staticsicon, "Statistics")}
            {renderNavLink("/manageusers", manageuser, "Manage Users")}
            {renderNavLink("/managedrivers", managedriver, "Manage Drivers")}
            {renderNavLink("/cylinders", cylinders, "Cylinders")}
            {renderNavLink("/gasorders", gasorder, "Gas Orders")}
            {renderNavLink("/earnings", earning, "Earnings")}
          </div>
        );
      case "distributor":
        return (
          <div className="flex flex-col gap-3">
            {renderNavLink(
              "/managesupplier",
              managesupillr,
              "Manage Suppliers"
            )}
          </div>
        );
      case "admin":
        return (
          <div className="flex flex-col gap-3">
            {renderNavLink("/", staticsicon, "Statistics")}
            {renderNavLink("/manageusers", manageuser, "Manage Users")}
            {renderNavLink("/managedrivers", managedriver, "Manage Drivers")}
            {renderNavLink("/cylinders", cylinders, "Cylinders")}
            {renderNavLink("/gasorders", gasorder, "Gas Orders")}
            {renderNavLink("/earnings", earning, "Earnings")}
            {renderNavLink("/suppersupplier", suppiler, "Super Suppliers")}
            {renderNavLink(
              "/managesupplier",
              managesupillr,
              "Manage Suppliers"
            )}
            {renderNavLink("/roles", roless, "Roles")}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-[270px] bg-black relative xl:fixed xl:top-0 xl:left-0 z-50 pb-0 h-screen max-h-screen overflow-y-hidden overflow-x-hidden px-[15px] py-[20px] 2xl:py-[40px]">
      <img src={Logo} className="w-[212px] h-[34px] mx-auto" />
      <div className="mt-[30px] 2xl:mt-[50px]">
        <ul className="space-y-[10px] 2xl:space-y-[15px]">
          {renderSidebarContent()}
        </ul>
        <div className="absolute bottom-2 w-[90%]">
          <ul className="space-y-[10px] 2xl:space-y-[20px]">
            {/* <li>
              <button className="py-[10px] 2xl:py-[15px] px-[20px] hover:bg-blue-dark rounded-[10px] w-full text-left">
                <ul className="inline-flex items-center text-start">
                  <li>
                    <img src={Setting} className="w-[18px] h-[18px]" />
                  </li>
                  <li className="f-f-sm-p text-tiny 2xl:text-lg text-grey ml-[15px]">
                    Settings
                  </li>
                </ul>
              </button>
            </li> */}
            <li>
              <button
                onClick={handleSignOut}
                className="py-[10px] 2xl:py-[15px] px-[20px] hover:bg-blue-dark rounded-[10px] w-full text-left"
              >
                <ul className="inline-flex items-center text-start">
                  <li>
                    <img src={Logout} className="w-[18px] h-[18px]" />
                  </li>
                  <li className="f-f-sm-p text-tiny 2xl:text-lg text-grey ml-[15px]">
                    Logout
                  </li>
                </ul>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
