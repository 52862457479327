import React, { useState } from "react";
import LinkSensi from "../../assets/images/LinkSensi Logo 1.svg";
import { Link } from "react-router-dom";
import { requestAccount } from "../../api/auth";

export default function RequestAccount() {
  const [email, setEmail] = useState("");
  const [accType, setAccType] = useState("");

  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");

  const showToast = (msg, type) => {
    setToastMsg(msg);
    setToastType(type);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 2000);
  };

  const requestAcc = async () => {
    try {
      await requestAccount(email, accType);
      showToast("Account request email sent", "success");
    } catch (e) {
      showToast(e.message, "error");
    }
  };

  return (
    <div className="signin-bg px-4 pt-24">
      <div className=" signincrd md:w-[30rem] mx-auto ">
        <div className=" py-3 ">
          <img src={LinkSensi} className=" w-[214px] h-[34px] mx-auto  " />
        </div>
        <div className=" login-crd p-[30px] sm:p-[40px] ">
          <h2 className=" text-grey f-f-b-n text-4xl  ">Request Account</h2>
          <h4 className=" text-grey-light  f-f-r-r text-base pt-2 ">
            Request an admin account to begin using our platform. You will
            receive an email with the necessary information, and our team will
            guide you through all the steps.
          </h4>
          <div className="my-4 flex flex-col gap-3">
            <div>
              <div className="text-white">Email</div>
              <div className="input input-bordered bg-transparent mt-1 flex items-center">
                <input
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="text"
                  placeholder="Enter your email"
                  className="w-full"
                />
              </div>
            </div>
            <div>
              <div className="text-white">Account type</div>
              <div className="mt-1">
                <select
                  value={accType}
                  onChange={(e) => {
                    setAccType(e.target.value);
                  }}
                  placeholder="Enter your password"
                  className="w-full select select-bordered bg-transparent"
                >
                  <option value="" disabled>
                    Choose
                  </option>
                  <option value="supplier">Supplier</option>
                  <option value="distributor">Distributor</option>
                </select>
              </div>
            </div>
          </div>

          <button
            onClick={requestAcc}
            className="btn btn-block my-4  bg-primary-dark hover:bg-primary-dark text-white "
          >
            Request
          </button>

          <h2 className=" f-f-m-r text-base text-grey text-center ">
            Already have an account?
            <Link to="/" className=" text-primary-dark cursor-pointer ">
              {" "}
              Sign In
            </Link>
          </h2>
        </div>
      </div>
      {toast === true ? (
        <div className="toast toast-top toast-center">
          <div className={`alert alert-${toastType} text-white`}>
            {toastMsg}
          </div>
        </div>
      ) : null}
    </div>
  );
}
