import React, { useState } from "react";
import LinkSensi from "../../assets/images/LinkSensi Logo 1.svg";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { Link } from "react-router-dom";
import { useAuth } from "../../api/auth";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [accType, setAccType] = useState("");
  const [passVisible, setPassVisible] = useState(false);
  const { auth, signIn, setAuthState } = useAuth();

  const [toast, setToast] = useState(false);
  const [toastMsg, setToastMsg] = useState("");
  const [toastType, setToastType] = useState("");

  const showToast = (msg, type) => {
    setToastType(type);
    setToastMsg(msg);
    setToast(true);
    setTimeout(() => {
      setToast(false);
    }, 4000);
  };

  const login = async () => {
    try {
      await signIn(email, password, accType);
      showToast("Signed In Successfully", "alert-success");
      window.location.reload();
    } catch (e) {
      showToast(e.message, "alert-error");
    }
  };

  return (
    <div className="signin-bg px-4 pt-24">
      <div className=" signincrd md:w-[30rem] mx-auto ">
        <div className=" py-3 ">
          <img src={LinkSensi} className=" w-[214px] h-[34px] mx-auto  " />
        </div>
        <div className=" login-crd p-[30px] sm:p-[40px] ">
          <h2 className=" text-grey f-f-b-n text-4xl  ">Sign-In</h2>
          <h4 className=" text-grey-light  f-f-r-r text-base pt-2 ">
            Access the Linksensi panel using your email and passcode.
          </h4>
          <div className="my-4 flex flex-col gap-3">
            <div>
              <div className="text-white">Account type</div>
              <div className="mt-1">
                <select
                  required
                  value={accType}
                  onChange={(e) => {
                    setAccType(e.target.value);
                  }}
                  className="w-full select select-bordered bg-transparent"
                >
                  <option value="" disabled>
                    Choose
                  </option>
                  <option value="supplier">Supplier</option>
                  <option value="distributor">Distributor</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
            </div>
            <div>
              <div className="text-white">Email</div>
              <div className="input input-bordered bg-transparent mt-1 flex items-center">
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="text"
                  placeholder="Enter your email"
                  className="w-full"
                />
              </div>
            </div>
            <div>
              <div className="text-white">Password</div>
              <div className="input input-bordered bg-transparent flex items-center gap-2 mt-1">
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type={`${passVisible ? "text" : "password"}`}
                  placeholder="Enter your password"
                  className="w-full"
                />
                <button
                  onClick={() => {
                    setPassVisible(!passVisible);
                  }}
                >
                  {passVisible ? <LuEye size={22} /> : <LuEyeOff size={22} />}
                </button>
              </div>
            </div>
          </div>

          <button
            onClick={login}
            className="btn btn-block my-4  bg-primary-dark hover:bg-primary-dark text-white "
          >
            Sign in
          </button>

          <h2 className=" f-f-m-r text-base text-grey text-center ">
            New on our platform?
            <Link
              to="/requestaccount"
              className=" text-primary-dark cursor-pointer "
            >
              {" "}
              Request account
            </Link>
          </h2>
        </div>
      </div>
      {toast === true ? (
        <div className="toast toast-center toast-top">
          <div className={`alert ${toastType} text-white`}>{toastMsg}</div>
        </div>
      ) : null}
    </div>
  );
}
