import React from 'react'
import Arrow from '../../assets/images/Arrow.svg'
import { Link } from 'react-router-dom'



export default function table() {
  return (
    <div className='p-[20px] sm:p-[34px]'>
      <Link to="/withdraw" >
        <ul className='inline-flex items-center space-x-5 mt-[14px]  '>
          <li><a href=""><img src={Arrow} className='w-[15px] h-[13px] ' alt="" /></a></li>
          <li>
            <h1 className='f-f-m-p text-2xl sm:text-3xl text-grey text-center md:text-start '>Set up withdrawals: Direct to local bank</h1>
          </li>
        </ul>
      </Link>
      <div className="box-set2 w-full mt-[30px] p-4 md:p-[30px]  ">

        <h2 className='f-f-sm-r text-2xl sm:text-3xl text-grey text-center md:text-start'>Company information</h2>

        <div className="grid grid-cols-12 gap-5 lg:gap-[59px] mt-[30px]">
          <div className="col-span-12 md:col-span-6">
            <h1 className='f-f-m-r text-sm sm:text-base text-grey'>Company Name</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='LinkSensi (Pty) Ltd' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Person in Charge</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='LSiphiwe Mabusela' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Contact Number</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='+27608548855' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Email</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='Siphiwe@linksensi.com' />
          </div>

          <div className="col-span-12 md:col-span-6">
            <h1 className='f-f-m-r text-sm sm:text-base text-grey'>Company Name</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='LinkSensi (Pty) Ltd' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Person in Charge</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='LSiphiwe Mabusela' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Contact Number</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='+27608548855' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Email</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='Siphiwe@linksensi.com' />
          </div>

          <div className="col-span-12 md:col-span-6">

            <h1 className='f-f-sm-r text-2xl sm:text-3xl text-grey text-center md:text-start mt-10'>Bank information</h1>

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Name of Bank</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='FNB Bank' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Account Number</h1>
            <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='630000000000000000' />

            <h1 className='f-f-m-r text-sm sm:text-base text-grey mt-[30px]'>Upload Supporting Document</h1>
            <div className="relative">
              <input type="text" className='w-full h-[40px] sm:h-[44px] rounded border border-blue-dark mt-2 bg-transparent f-f-r-r text-[11px] sm:text-tiny text-grey-light p-3 outline-none' placeholder='Siphiwe@linksensi.com' />

              <button className='absolute w-[97px] h-[40px] sm:h-[44px] bg-blue-dark border border-blue-dark f-f-r-r text-sm sm:text-tiny text-grey top-0 right-0 mt-2'>Browse</button>

            </div>
          </div>

        </div>

        <div className="text-end">
          <button className='w-full md:w-[149px] h-[35px] md:h-[41px] rounded-[10px] bg-primary-dark f-f-sm-n text-sm md:text-base text-white-light mt-10 mb-[10px] '>Save Information</button>
        </div>


      </div>
    </div>
  )
}
