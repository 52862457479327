import React from "react";
import Sidebar from "../Common/Sidebar";
import Header from "../Common/Header";
import Hero from "./Hero";

export default function Earnings() {
  return (
    <div>
      <div className=" hidden xl:block">
        <Sidebar />
      </div>
      <div className=" xl:ml-[270px]   ">
        <Header />
        <Hero />
      </div>
    </div>
  );
}
