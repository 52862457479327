import React, { useEffect } from "react";
import Mobiledrawer from "./Mobiledrawer";
import { useAuth } from "../../api/auth";
import { useSupplier } from "../../api/index";

export default function Header() {
  const { auth, setAuthState } = useAuth();
  const { supplier, loading, error } = useSupplier();

  const accountType = auth.accountType;
  const formattedAccountType = accountType
    ? accountType.charAt(0).toUpperCase() + accountType.slice(1).toLowerCase()
    : "";

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    const accountType = localStorage.getItem("accountType");
    if (accessToken && accountType) {
      setAuthState(accessToken, accountType);
    }
  }, [setAuthState]);

  return (
    <>
      <div className=" py-4 px-[33px] ">
        <div className="flex items-center justify-between">
          <div className="text-white font-semibold">
            {formattedAccountType} Management System
          </div>
          <div className="flex items-center gap-2">
            {loading ? (
              <p className="text-white font-semibold">Loading...</p>
            ) : error ? (
              <p className="text-white font-semibold">{"----"}</p>
            ) : (
              <p className="text-white font-semibold">{supplier.companyName}</p>
            )}
            <div className=" ml-[15px] xl:hidden ">
              <Mobiledrawer />
            </div>
          </div>
        </div>
      </div>
      <div className=" border border-blue-dark "></div>
    </>
  );
}
